@import url(https://fonts.googleapis.com/css?family=Michroma);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav-wrapper {
    margin: auto;
    max-width: 1126px;
    padding: 10px;
}

/* header */  
.header {
    background-color: #fff;
    box-shadow: 1px 1px 4px 0 rgba(0,0,0,.1);
    position: fixed;
    width: 100%;
    z-index: 3;
}
  
.header ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    background-color: #fff;
}
  
.header li a {
    display: block;
    padding: 20px 20px;
    border-right: 1px solid #f4f4f4;
    text-decoration: none;
    color: #000;
}
  
.header li a:hover,
.header .menu-btn:hover {
    background-color: #f4f4f4;
}
  
.header .logo {
    display: block;
    float: left;
    font: bold 2em 'Michroma';
    padding: 10px 20px;
    text-decoration: none;
    color: #000;
}
  
/* menu */
.header .menu {
    clear: both;
    max-height: 0;
    transition: max-height .2s ease-out;
}
  
/* menu icon */  
.header .menu-icon {
    cursor: pointer;
    display: inline-block;
    float: right;
    padding: 28px 20px;
    position: relative;
    -webkit-user-select: none;
            user-select: none;
}
  
.header .menu-icon .navicon {
    background: #333;
    display: block;
    height: 2px;
    position: relative;
    transition: background .2s ease-out;
    width: 18px;
}
  
.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
    background: #333;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
}
  
.header .menu-icon .navicon:before {
    top: 5px;
}
  
.header .menu-icon .navicon:after {
    top: -5px;
}
  
/* menu btn */  
.header .menu-btn {
    display: none;
}
  
.header .menu-btn:checked ~ .menu {
    max-height: 240px;
}
  
.header .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
}
  
.header .menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
}
  
.header .menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
}
  
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
}

/* 48em = 768px */
@media (min-width: 48em) {
    .header li {
      float: left;
    }
    
    .header li a {
      padding: 20px 30px;
    }

    .header .menu {
      clear: none;
      float: right;
      max-height: none;
    }

    .header .menu-icon {
      display: none;
    }
}
.footer {
    background: #37387a;
    color: #fff;
    padding: 2rem 0;
    text-align: center;
    font-size: .8rem;
    color: rgba(white,.4);
}

.footer ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.footer li {
    display: inline-block;
}

.footer a {
    display: block;
    padding: .4rem .7rem;
    font-size: .9rem;
    text-decoration: none;
    color: rgba(255, 255, 255, 1);
}

.footer a:hover {
    color: rgb(214, 214, 214);
}
* {
    box-sizing: border-box;
}

.main-content {
    margin: 4.5rem 0rem 2rem 0rem;
}

.content-container {
    margin: 2.5rem auto 2.5rem auto;
    max-width: 1126px;
    min-height: 50vh;
    padding: 10px;
}

/*Contact us*/
tr, td {
	vertical-align: baseline;
}

.row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    min-height: 480px;
}

.col-left {
		text-align: center;
		padding:0;
		margin-left: 20px;
		margin-right: 20px;
        box-sizing:border-box;
        -webkit-box-sizing:border-box;
        -moz-box-sizing:border-box;
        -webkit-font-smoothing:antialiased;
        -moz-font-smoothing:antialiased;
        -o-font-smoothing:antialiased;
        font-smoothing:antialiased;
        text-rendering:optimizeLegibility;

        width: 42%;
        min-width: 250px;
        line-height: 20px;
        position: relative;
}
	
.col-right {
		padding:0;
		margin-left: 20px;
		margin-right: 20px;
        width: 40%;
        min-width: 250px;
        line-height: 20px;
        position: relative;
}

#contact input[type="text"], #contact input[type="email"], #contact input[type="tel"], #contact textarea, #contact button[type="submit"] { 
    font:400 12px/16px "Open Sans", Helvetica, Arial, sans-serif; 
}

#contact, #details {
	padding: 10px;
}

fieldset {
	border: medium none !important;
	margin: 0 0 10px;
	min-width: 100%;
	padding: 0;
	width: 100%;
}

#contact input[type="text"], #contact input[type="email"], #contact input[type="tel"], #contact input[type="url"], #contact textarea {
    width:90%;
	border:1px solid #CCC;
	background:#FFF;
	margin:0 0 5px;
	padding:10px;
}

#contact input[type="text"]:hover, #contact input[type="email"]:hover, #contact input[type="tel"]:hover, #contact input[type="url"]:hover, #contact textarea:hover {
	transition:border-color 0.3s ease-in-out;
	border:1px solid #AAA;
}

#contact textarea {
	height:100px;
	max-width:100%;
    resize:none;
}

#contact button[type="submit"] {
	cursor:pointer;
	width:100%;
	border:none;
	background:#37387a;;
	color:#FFF;
	margin:0 0 5px;
	padding:10px;
	font-size:15px;
}

#contact button[type="submit"]:hover {
	background:#22234b;
	transition:background-color 0.3s ease-in-out;
}

#contact button[type="submit"]:active { box-shadow:inset 0 1px 3px rgba(0, 0, 0, 0.5); }

#contact input:focus, #contact textarea:focus {
	outline:0;
	border:1px solid #999;
}
::-webkit-input-placeholder {
 color:#888;
}
:-moz-placeholder {
 color:#888;
}
::-moz-placeholder {
 color:#888;
}
:-ms-input-placeholder {
 color:#888;
}
header{
    color: white;
    background: #37387a;
    background-image: url(/static/media/space.247c7b29.jpg);
    min-height: 50vh;
    padding-top: 20vh;
    text-align: center;
    position: relative;
    z-index: 1;
    overflow: hidden;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
}
  
header h1{
    font-size: 3rem;
    margin: 0 0 1rem;
}

header h2{
    font-weight: 300;
    font-size: 1.5rem;
    margin: 0 0 1rem;
}

header a{
    color: #ffff;
}

.Contact {
  text-align: center;
  max-width: 300px;
  margin: 20px auto;
}

Form {
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 20px;
}
.body-wrap {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App {
  text-align: center;
  max-width: 300px;
  margin: 20px auto;
}

Form {
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 20px;
}
