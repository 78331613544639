.body-wrap {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App {
  text-align: center;
  max-width: 300px;
  margin: 20px auto;
}

Form {
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 20px;
}